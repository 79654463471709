<script>
import calendar from "@/components/Calendars.vue";
import reportSales from "@/components/printers/reports/SalesReportThermal.vue";
import mixin from "@/mixins";
import ListData from "./components/ListDataItems";
import Print from "./components/PrintDataItems";
export default {
  components: {
    calendar,
    reportSales,
    ListData,
    Print,
  },
  mixins: [mixin],
  data: () => ({
    type: "TODOS",
    dialog: false,
    headers: [
      { text: "Data/Hora", value: "DATA_HORA", sortable: false },
      { text: "Origem", value: "ORIGEM", sortable: false },
      { text: "Destino", value: "DESTINO", sortable: false },
      { text: "Valor", value: "TOTAL", sortable: false },
    ],
    search: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      type_filter: "AGENCIAS",
      item: null,
    },
    items: [],
    agencia_motoristas: [],
    valid: true,
    total: 0,
    comissao: 0,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
  }),
  created() {
    this.mixin;
  },
  methods: {
    printItem() {
      this.$htmlToPaper("printReport");
    },
    checkFilter() {
      if (this.type == "TODOS") {
        this.search.item = null;
      }
    },
    loadValues() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/comissoes/passagens`, {
          dateStart: this.search.dateStart,
          dateEnd: this.search.dateEnd,
          type_filter: this.search.type_filter,
          item: this.search.item,
        })
        .then((req) => {
          this.items = req.data;
          req.data.map((item) => {
            this.total += item.TOTAL;
          });
          this.$store.dispatch("activeLoader", false);
        })
        .catch((err) => {
          this.$store.dispatch("activeLoader", false);
        });
    },
    async loadFilter() {
      this.agencia_motoristas = [];
      this.$store.dispatch("activeLoader", true);
      if (this.search.type_filter == "AGENCIAS") {
        const agencias = await this.$http.get("/agencias");
        this.agencia_motoristas = agencias.data;
        this.$store.dispatch("activeLoader", false);
      } else if (this.search.type_filter == "MOTORISTAS") {
        const motoristas = await this.$http.get("/motoristas/ativos");
        this.agencia_motoristas = motoristas.data;
        this.$store.dispatch("activeLoader", false);
      }
    },
  },
};
</script>
