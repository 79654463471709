<template>
  <div style="width: 100%" id="printReport">
    <header
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
    >
      <img
        src="@/assets/logoBlue.png"
        alt="[logo]"
        style="width: 600px; padding: 20px"
      />
      <div>
        <h5>
          {{
            search.type_filter == "AGENCIAS"
              ? "RELATÓRIO DE ENCOMENDAS POR AGÊNCIAS"
              : "RELATÓRIO DE ENCOMENDAS POR MOTORISTAS"
          }}
        </h5>
        <p>
          Período de: {{ convertDate(search.dateStart) }} à
          {{ convertDate(search.dateEnd) }}
        </p>
      </div>
    </header>
    <br />
    <table
      v-for="(item, ind) in items"
      :key="ind"
      style="
        border-collapse: collapse;
        border: 1px solid rgb(214, 214, 214);
        border-top: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
      "
    >
      <thead>
        <tr style="border: 1px solid rgb(214, 214, 214); text-align: center">
          <th
            style="
              border: 1px solid rgb(214, 214, 214);
              padding: 5px;
              text-align: center;
              background: gray;
              color: #fff;
            "
          >
            {{ item.NOME }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <table
              class="table"
              style="
                border-collapse: collapse;
                border: 1px solid rgb(214, 214, 214);
                border-top: 0;
                text-align: center;
                width: 100%;
              "
            >
              <thead>
                <tr
                  style="
                    border: 1px solid rgb(214, 214, 214);
                    text-align: center;
                  "
                >
                  <th
                    v-for="(header, ih) in headers"
                    :key="ih"
                    style="
                      border: 1px solid rgb(214, 214, 214);
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="(data, idx) in item.DATA" :key="idx">
                <tr style="border: 1px solid rgb(214, 214, 214)">
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ convertDateTimeNew(data.DATA_HORA) }}
                  </td>
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ data.REMETENTE }}
                  </td>
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ data.DESTINATARIO }}
                  </td>
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ data.ORIGEM }}
                  </td>
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ data.DESTINO }}
                  </td>
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ selectCobranca(data.COBRANCA) }}
                  </td>
                  <td>
                    {{ convertCurrent(data.TOTAL) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td
            style="
              border: 1px solid rgb(214, 214, 214);
              padding: 8px;
              text-align: center;
            "
          >
            <strong>TOTAL: {{ convertCurrent(item.VALOR_TOTAL) }}</strong> |
            <strong>COMISSÃO: {{ convertCurrent(item.COMISSAO) }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items", "headers", "search"],
  mixins: [mixin],
};
</script>

