<template>
  <v-card class="mb-10" flat>
    <v-card-text v-for="(item, ind) in items" :key="ind">
      <v-row dense>
        <v-col cols="12" sm="8">
          <h3 class="mb-2">{{ item.NOME }}</h3>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> TOTAL: {{ convertCurrent(item.VALOR_TOTAL) }} </strong>
        </v-col>
        <v-col cols="12" sm="2">
          <strong> COMISSÃO: {{ convertCurrent(item.COMISSAO) }} </strong>
        </v-col>
      </v-row>
      <v-divider />
      <v-data-table :headers="headers" :items="item.DATA" class="elevation-0">
        <template v-slot:item.TOTAL="{ item }">
          {{ convertCurrent(item.TOTAL) }}
        </template>
        <template v-slot:item.DATA_HORA="{ item }">
          {{ convertDateTimeNew(item.DATA_HORA) }}
        </template>
        <template v-slot:item.COBRANCA="{ item }">
          {{ selectCobranca(item.COBRANCA) }}
        </template>
      </v-data-table>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items", "headers"],
  mixins: [mixin],
};
</script>
